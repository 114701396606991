<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 px-0">
        <div class="img_container">
          <div class="img-box">
            <img src="@/assets/prod1.webp" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-6 px-0">
        <div class="detail-box rounded">
          <div class="heading_container ">
            <h2>
              Who Are We?
            </h2>
          </div>
          <p>
            Tikuri Vaidic Agro Services LLP is a promising startup that specializes in producing and trading organic
            products. Our unique hybrid business model allows us to offer a diverse range of high-quality organic honey,
            spices, and natural fertilizers that are suitable for every home kitchen. Our focus on promoting sustainable
            practices extends to our consultancy services, where we help farmers adopt zero-budget farming techniques and
            other innovative farming practices that can help increase their income. Our goal is to empower farmers and
            consumers alike to embrace a healthier and more sustainable way of living.
          </p>
          <div class="btn-box">
            <a href="/about">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComp"
}
</script>